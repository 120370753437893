import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
      url: String
  }

  static get targets() {
    return [
      "query",
      "results",
      "selectionDisplay",
      "selectionValue"
    ]
  }

  connect() {
    //console.log("Search controller connected")
  }

  disconnect() {
    this.stopPrevious()
  }

  search() {
    let query = this.queryTarget.value
    //console.log("Starting search for:", query)

    this.debounce(query)
  }

  debounce(query) {
    let delay = parseInt(this.data.get("debounce")) || 400
    this.stopPrevious()

    this.debouncer = setInterval((query) => {
      this.fetchResults(query)
      this.stopPrevious()
    }, delay)
  }

  stopPrevious() {
    if (this.debouncer) {
      clearInterval(this.debouncer)
    }
  }

  hide(event) {
    if (this.element.contains(event.target) == false) {
      // hide if it's open
      if (this.resultsTarget.classList.contains("d-block")) {
        this.resultsTarget.classList.remove("d-block")
        this.resultsTarget.classList.add("d-none")
      }
    }
  }

  fetchResults(query) {
    let encodedQuery = encodeURIComponent(this.queryTarget.value)
    // let encodedAuthenticityToken = this.authenticityToken()
    let url = `${this.urlValue}?query=${encodedQuery}`

    fetch(url, {
      credentials: "same-origin",
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      return response
    })
    .then(response => response.text())
    .then(html => {
      this.resultsTarget.innerHTML = html
      this.resultsTarget.classList.remove("d-none")
      this.resultsTarget.classList.add("d-block")
    })
  }

  selected(event) {
    let theSelected = event.currentTarget
    let user_id = theSelected.dataset.userId
    this.selectionValueTarget.value = user_id
    this.selectionDisplayTarget.innerHTML = theSelected.dataset.val
    this.queryTarget.value = ''
    this.resultsTarget.classList.remove("d-block")
    this.resultsTarget.classList.add("d-none")
  }
}
